import React, {FunctionComponent} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql, useStaticQuery} from 'gatsby'
import Img from "gatsby-image"

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'

const Lez: FunctionComponent = () => {
    const { t } = useTranslation()

    const data = useStaticQuery(graphql`
        query {
          img: file(relativePath: { eq: "images/lez.jpg" }) {
            childImageSharp {
              fixed(width: 260, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
    `)

    return <Section id="lez">
        <SectionHeader title={t('lez.title')}>
            <p dangerouslySetInnerHTML={{__html:t('lez.link')}} />
        </SectionHeader>

        <span style={{marginRight: '1em', display: 'inline-block', float: 'left'}}>
            <a href={'https://www.lez2020.gent'} target={'_blank'}>
                <Img fixed={data.img.childImageSharp.fixed} alt={t('lez.title')} />
            </a>
        </span>

        <p dangerouslySetInnerHTML={{__html:t('lez.paragraph1')}} />
        <p dangerouslySetInnerHTML={{__html:t('lez.paragraph2')}} />
    </Section>
}

export default Lez
