import React, {FunctionComponent, SyntheticEvent, useState} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import Recaptcha from 'react-recaptcha'
import {FaCheck} from 'react-icons/fa'

import Loader from '../Loader'

type IFormData = {
    name: string,
    email: string,
    message: string
}

const ContactForm: FunctionComponent = () => {
    const { t } = useTranslation()

    const [name, setName]       = useState<string>('')
    const [email, setEmail]     = useState<string>('')
    const [message, setMessage] = useState<string>('')

    const [gRecaptchaResponse, setGRecaptchaResponse] = useState<string>()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isSuccess, setIsSuccess]       = useState<boolean>(false)

    const onSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        if (! validate()) {
            e.preventDefault()
            return false
        }

        e.preventDefault()
        console.log(e.target);
        const form     = e.target;
        const formData = new FormData(e.target);
        const xhr      = new XMLHttpRequest();

        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                setIsSuccess(true);
                setIsSubmitting(false);
                setMessage('');
                setName('');
                setEmail('');
            } else {
                setIsSubmitting(false);
                setIsSuccess(false);
                setGRecaptchaResponse(undefined);
            }
        };
        xhr.send(formData);

        return false;
    }

    const validate = (): boolean => {
        if (! name || ! email || ! message || !gRecaptchaResponse) {
            return false
        }

        if (! validateEmail(email)) {
            return false
        }

        return true
    }

    const validateEmail = (email: string): boolean => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(email).toLowerCase());
    }

    return <form onSubmit={onSubmit} method={'post'} action={'https://formspree.io/mgengnwp'}>
        <div className="fields">
            <div className="field half">
                <input
                    type="text"
                    value={name}
                    onChange={e => setName(e.currentTarget.value)}
                    name="name"
                    id="name"
                    placeholder={t('contact.name')}
                    required={true}
                />
            </div>
            <div className="field half">
                <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                    name="email"
                    id="email"
                    placeholder={t('contact.email')}
                    required={true}
                />
            </div>
            <div className="field">
                <textarea
                    value={message}
                    onChange={e => setMessage(e.currentTarget.value)}
                    name="message"
                    id="message"
                    placeholder={t('contact.message')}
                    rows={7}
                    required={true}
                />
            </div>
            <input type={'hidden'} name={'g-recaptcha-response'} value={gRecaptchaResponse} />
            {
                gRecaptchaResponse || isSuccess || isSubmitting
                    ? null
                    : <div className="field">
                        <Recaptcha
                            sitekey="6Lcte8gZAAAAAJbHD6FzVzFXcIqtdVw5G3cg4IF6"
                            verifyCallback={(response) => setTimeout(() => setGRecaptchaResponse(response), 700)}
                        />
                    </div>
            }
        </div>
        {
            isSuccess
                ? <div className={"field"}>
                    <span style={{fontSize: '2em', color: 'green'}}><FaCheck /></span> {t('contact.sent_success')}
                </div>
                : isSubmitting
                    ? <div className="field"><Loader /></div>
                    : gRecaptchaResponse
                        ? <ul className="actions">
                            <li><button type="submit" className="button primary">{t('contact.send')}</button></li>
                        </ul>
                        : null
        }

    </form>
}

export default ContactForm
