import React, {FunctionComponent} from 'react'
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next'

const LanguageSelector: FunctionComponent = () => {
    const [t] = useTranslation()

    const {language, languages, changeLanguage} = useI18next()

    const languageFlagMap = {
        en: 'gb'
    }

    return <ul className="language-selector">
        {languages.filter((lng) => lng !== language).map((lng) => (
            <li key={lng}>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        changeLanguage(lng);
                    }}>
                    <span className={`flag-icon flag-icon-${languageFlagMap[lng] ? languageFlagMap[lng] : lng}`}></span>

                </a>
            </li>
        ))}
    </ul>
}

export default LanguageSelector
