import React, {FunctionComponent} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'
import {FaFacebook, FaInstagram} from 'react-icons/fa'
import ContactForm from '../ContactForm'

const Contact: FunctionComponent = () => {
    const { t } = useTranslation()

    return <Section id={'contact'}>
        <SectionHeader title={t('contact.title')}>
            <footer>
                <ul className="items">
                    <li>
                        <h3>{t('contact.email')}</h3>
                        <a href="mailto:info@hotel-orion.be">info@hotel-orion.be</a>
                    </li>
                    <li>
                        <h3>{t('contact.phone')}</h3>
                        <a href="tel:+3293951295">+32 (0)93 95 12 95</a>
                    </li>
                    <li>
                        <h3>{t('contact.address')}</h3>
                        <address>
                            Krijgslaan 181<br />
                            9000 Gent, België
                        </address>
                    </li>
                    <li>
                        <h3>{t('contact.elsewhere')}</h3>
                        <ul className="icons">
                            <li>
                                <a href="https://www.facebook.com/HotelOrionGent/" target={'_blank'}>
                                    <FaFacebook />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/hoteloriongent/" target={'_blank'}>
                                    <FaInstagram />
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </footer>
        </SectionHeader>
        <p>{t('contact.form_title')}</p>
        <ContactForm />
    </Section>
}

export default Contact
