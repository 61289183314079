import React, {FunctionComponent} from 'react'
import {Helmet} from 'react-helmet'
import {useI18next} from 'gatsby-plugin-react-i18next'

const Seo: FunctionComponent = () => {
    const {t, language} = useI18next();

    return <Helmet
        htmlAttributes={{
            lang: language,
        }}
        title={t('seo.title')}
        meta={[
            {
                name: `description`,
                content: t('seo.description'),
            },
            {
                property: `og:title`,
                content: t('seo.title'),
            },
            {
                property: `og:description`,
                content: t('seo.description'),
            },
            {
                property: `og:type`,
                content: `website`,
            },
            {
                name: `twitter:card`,
                content: `summary`,
            },
            {
                name: `twitter:title`,
                content: t('seo.title'),
            },
            {
                name: `twitter:description`,
                content: t('seo.description'),
            },
        ]}
    />
}

export default Seo
