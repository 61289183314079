import React, {FunctionComponent} from 'react'

const Loader: FunctionComponent = () => {
    return <div className={'loader'}>
        <svg width="40" height="40" viewBox="0 0 100 100">
            <polyline
                className="line-cornered stroke-still"
                points="0,0 100,0 100,100"
                strokeWidth="10"
                fill="none"
            />
            <polyline
                className="line-cornered stroke-still"
                points="0,0 0,100 100,100"
                strokeWidth="10"
                fill="none"
            />
            <polyline
                className="line-cornered stroke-animation"
                points="0,0 100,0 100,100"
                strokeWidth="10"
                fill="none"
            />
            <polyline
                className="line-cornered stroke-animation"
                points="0,0 0,100 100,100"
                strokeWidth="10"
                fill="none"
            />
        </svg>
    </div>
}

export default Loader
