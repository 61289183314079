import React, {FunctionComponent, useState} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import Img from "gatsby-image"

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'
import Modal from '../Modal/Modal'
import {graphql, useStaticQuery} from 'gatsby'

const Gallery: FunctionComponent = () => {
    const { t } = useTranslation()
    const [modalImageIndex, setModalImageIndex] = useState<null|number>(null)

    const data = useStaticQuery(graphql`
        query {
          img01: file(relativePath: { eq: "images/gallery/building01.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          img02: file(relativePath: { eq: "images/gallery/breakfast01.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          img03: file(relativePath: { eq: "images/gallery/atrium01.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          img04: file(relativePath: { eq: "images/gallery/garden01.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          img05: file(relativePath: { eq: "images/gallery/room01.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
    `)

    return <Section id="gallery">
        <SectionHeader title={t('gallery.title')}>
            <p>{t('gallery.description')}</p>
        </SectionHeader>

        <div className={'gallery'}>
            {
                Object.keys(data).map((image, index) => {
                    return <a
                        className={`image ${(index + 1) % 3 === 0 ? 'landscape' : 'portrait'}`}
                        onClick={() => setModalImageIndex(index)}
                        key={'galleryImage' + index}
                    >
                        <Img fluid={{ ...data[image].childImageSharp.fluid, aspectRatio: 1.5 }} />
                    </a>
                })
            }
        </div>

        {
            null !== modalImageIndex
                ? <Modal
                    onClose={() => setModalImageIndex(null)}
                    onNext={() => setModalImageIndex((modalImageIndex + 1) >= Object.keys(data).length ? 0 : modalImageIndex + 1)}
                    onPrev={() => setModalImageIndex(modalImageIndex === 0 ? Object.keys(data).length - 1 : modalImageIndex - 1)}
                >
                    <div style={{width: '100%', height: '100%'}}>
                        <img src={data['img0' + (modalImageIndex + 1)].childImageSharp.fluid.src} />
                    </div>
                </Modal>
                : null
        }

    </Section>
}

export default Gallery
