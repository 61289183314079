import React, {FunctionComponent} from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import {graphql, useStaticQuery} from 'gatsby'
import Img from "gatsby-image"

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'
import BookButton from '../BookButton'

const Location: FunctionComponent = () => {
    const { t } = useTranslation()

    const data = useStaticQuery(graphql`
        query {
          img: file(relativePath: { eq: "images/promo/back.jpg" }) {
            childImageSharp {
              fixed(width: 520, quality: 70) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
    `)

    return <Section id="location">
        <SectionHeader title={t('location.title')}>
            <p>
                {t('location.description')}
            </p>
        </SectionHeader>

        <div dangerouslySetInnerHTML={{__html: t('location.list')}} />
        <Link to={'/#googlemap'}>View map</Link>

    </Section>
}

export default Location
