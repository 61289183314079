import React, {FunctionComponent, useRef, useState} from 'react'

const GoogleMap: FunctionComponent = () => {
    const [pointerEvents, setPointerEvents] = useState<'none'|'auto'>('none')


    return <div
        style={{ height: '80vh', width: '100%' }}
        onClick={() => setPointerEvents('auto')}
        onMouseLeave={() => setPointerEvents('none')}
        id={'googlemap'}
    >
        <iframe
            src="https://www.google.com/maps/d/embed?mid=19VmDvTXa6s74tiL0b44DpzEZr5JLWZOz"
            style={{pointerEvents, width: '100%', height: '100%' }}
        />
    </div>
}

export default GoogleMap
