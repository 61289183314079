import React, {FunctionComponent, useState} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'
import {FaCoffee, FaDumbbell, FaParking, FaShower, FaSwimmingPool, FaBusinessTime} from 'react-icons/fa'
import Modal from '../Modal/Modal'

const Facilities: FunctionComponent = () => {
    const { t } = useTranslation()

    const [modalFacilityIndex, setModalFacilityIndex] = useState<null|number>(null)

    const facilities = [
        {
            icon: <FaCoffee />,
            title: 'facilities.breakfast.title',
            description: 'facilities.breakfast.description'
        },
        {
            icon: <FaSwimmingPool />,
            title: 'facilities.pool.title',
            description: 'facilities.pool.description'
        },
        {
            icon: <FaShower />,
            title: 'facilities.sauna.title',
            description: 'facilities.sauna.description'
        },
        {
            icon: <FaDumbbell />,
            title: 'facilities.fitness.title',
            description: 'facilities.fitness.description'
        },
        {
            icon: <FaParking />,
            title: 'facilities.parking.title',
            description: 'facilities.parking.description'
        },
        // {
        //     icon: <FaBusinessTime />,
        //     title: 'facilities.meeting_rooms.title',
        //     description: 'facilities.meeting_rooms.description'
        // }
    ]

    return <Section id="facilities">
        <SectionHeader title={t('facilities.title')}>
            <p>{t('facilities.description')}</p>
        </SectionHeader>
        <ul className={'facilities-list'}>
            {
                facilities.map(
                    (facility, index) => <li onClick={() => setModalFacilityIndex(index) } key={`facilities${index}`}>
                        {facility.icon}&nbsp;{t(facility.title)}
                    </li>
                )
            }
        </ul>
        {
            null !== modalFacilityIndex
                ? <Modal
                    onClose={() => setModalFacilityIndex(null)}
                    onPrev={() => setModalFacilityIndex(modalFacilityIndex === 0 ? facilities.length - 1 : modalFacilityIndex - 1)}
                    onNext={() => setModalFacilityIndex((modalFacilityIndex + 1) >= facilities.length ? 0 : modalFacilityIndex + 1)}
                >
                    <div style={{width: '43rem', maxWidth: '43rem', padding: '1em', backgroundColor: 'white'}}>
                        <h1>{facilities[modalFacilityIndex].icon} {t(facilities[modalFacilityIndex].title)}</h1>
                        <p>{t(facilities[modalFacilityIndex].description)}</p>
                    </div>
                </Modal>
                : null
        }

    </Section>
}

export default Facilities
