import React, {FunctionComponent, useEffect, useState} from 'react'
import {HotKeys} from 'react-hotkeys'

type IProps = {
    onClose: () => void,
    onNext?: () => void,
    onPrev?: () => void
}

const Modal: FunctionComponent<IProps> = (props) => {
    const classes = ['modal', 'loaded', 'visible']

    const keyMap = {
        ARROW_RIGHT: "ArrowRight",
        ARROW_LEFT:  "ArrowLeft",
        ESCAPE:      "Escape"
    };

    const handlers = {
        ARROW_RIGHT: () => props.onNext ? props.onNext() : null,
        ARROW_LEFT:  () => props.onPrev ? props.onPrev() : null,
        ESCAPE:      () => props.onClose()
    };

    return <HotKeys keyMap={keyMap} handlers={handlers}>
        <div className={classes.join(' ')}>
            <button className={'modal-close'} onClick={() => props.onClose()} />
            {props.onPrev ? <button className={'modal-prev'} onClick={() => props.onPrev()} /> : null}
            {props.onNext ? <button className={'modal-next'} onClick={() => props.onNext()} /> : null}
            <div className={'inner'}>
                {props.children}
            </div>
        </div>
    </HotKeys>
}

export default Modal
