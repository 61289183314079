import React, {FunctionComponent} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql, useStaticQuery} from 'gatsby'
import Img from "gatsby-image"

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'
import imgBack from '../../images/promo/back.jpg'
import BookButton from '../BookButton'

const PromoCodes: FunctionComponent = () => {
    const { t } = useTranslation()

    const data = useStaticQuery(graphql`
        query {
          img: file(relativePath: { eq: "images/promo/back.jpg" }) {
            childImageSharp {
              fixed(width: 520, quality: 70) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
    `)

    return <Section id="promocodes">
        <SectionHeader title={t('promocodes.title')}>
            <p>
                {t('promocodes.description')}
            </p>
        </SectionHeader>

        <Img fixed={data.img.childImageSharp.fixed} className={'image'} style={{display: 'block', marginBottom: '1em'}} />

        <BookButton />
    </Section>
}

export default PromoCodes
