import React, {FunctionComponent} from 'react'
import {graphql} from 'gatsby';

import GoogleMap from '../components/GoogleMap'
import Header from '../components/PageSection/Header'
import Facilities from '../components/PageSection/Facilities'
import Contact from '../components/PageSection/Contact'
import Lez from '../components/PageSection/Lez'
import PromoCodes from '../components/PageSection/PromoCodes'
import Gallery from '../components/PageSection/Gallery'
import Footer from '../components/PageSection/Footer'
import LanguageSelector from '../components/LanguageSelector'
import Seo from '../components/PageSection/Seo'
import Location from '../components/PageSection/Location'

const IndexPage: FunctionComponent = () => <div id="wrapper">
    <Header page={'index'} />

    <Seo />

    <Facilities />

    <Gallery />

    <Location />

    <PromoCodes />

    <Lez />

    <GoogleMap/>

    <Contact />

    <Footer page={'index'} />

    <LanguageSelector />
</div>

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
